@import './fonts/roboto.css';

/* cкрыть Refresh button из верхней панели */
.MuiToolbar-root button:nth-child(3) {
  display: none;
}

.MuiToolbar-root .app-loader {
  display: none;
}

[role="tooltip"]{
  z-index: 9999999!important;
}